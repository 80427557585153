<template>
  <div>
    <!--    <van-nav-bar title="本市影院本年度票房排行榜" left-text="返回" left-arrow @click-left="toTopPage()">-->
    <!--    </van-nav-bar>-->
    <div
      class="card"
      :class="{
        levelA: level === 'A',
        levelB: level === 'B',
        levelC: level === 'C',
        levelD: level === 'D',
        levelE: level === 'E',
      }"
    >
      <div class="level">
        <div class="level-number">{{ level }}</div>
        <div class="level-title">安全等级</div>
      </div>
      <div class="content">
        <div class="content-name">{{ cinemaName }}</div>
        <div class="content-line"></div>
        <div class="content-from">自省消防支队</div>
      </div>
    </div>
    <div class="fireScore">
      <div class="city">
        <div>城市评分</div>
        <div>
          {{ cityScore }}
        </div>
      </div>
      <div class="cinema">
        <div>影院消防信用评分</div>
        <div>
          {{ DistrictScore }}
        </div>
      </div>
    </div>
    <div class="desc">
      说明：消防安全等级按照A、B、C、D、E五个等级来进行划分分别对应五种不同颜色，数据每7天更新一次，Ａ优秀Ｂ良好Ｃ一般Ｄ不好Ｅ严重。
    </div>
  </div>
</template>

<script>
import { zjApi } from '@/api/index'
import { Toast } from 'vant'
export default {
  name: 'fireSafety',
  data() {
    return {
      cinemaId: '',
      cinemaName: '',
      level: 'A',
      city: '嘉兴市',
      cityScore: '99',
      DistrictScore: '',
    }
  },
  mounted() {
    this.cinemaId = this.$route.query.id || ''
    const cinema = localStorage.getItem('selectCinema')
      ? JSON.parse(localStorage.getItem('selectCinema'))
      : this.cinemaList[0]
    // console.log({"JJ":cinema});
    this.cinemaName = cinema.text
    this.getData()
  },
  methods: {
    getData() {
      const userCode = this.$store.state.userInfo.mobile
      const query = `{"UserCode":"${userCode}","CmdIndex":"101606","cmdArgs":["${this.cinemaId}"]}`
      zjApi.getPs({ ps: query }).then((r) => {
        console.log(r)
        const { data, Message, Success } = r
        if (!Success) {
          Toast(Message)
        } else {
          this.level = data.XfCinemaDj
          this.cityScore = data.XfCityScore
          this.DistrictScore = data.XfDistrictScore
        }
      })
    },
    toTopPage() {
      window.history.back()
    },
  },
}
</script>

<style scoped lang="less">
.van-nav-bar {
  background: #5087ec;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */

  /deep/ .van-nav-bar__title {
    color: white;
  }

  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}

.card {
  box-sizing: border-box;
  border-radius: 3px;
  height: 3rem;
  width: 9.5rem;
  margin: 0.3rem auto;
  background-blend-mode: screen;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.3);
  display: flex;
  text-align: center;
  color: white;

  .level {
    flex: 1;

    &-title {
      font-size: 0.16rem;
    }

    &-number {
      font-size: 60px;
    }
  }

  .content {
    flex: 4;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-line {
      border-bottom: 1px dashed #fff;
      width: 99%;
    }

    &-name {
      padding: 25px 0;
      font-size: 0.7rem;
    }

    &-from {
      text-align: right;
      font-size: 0.4rem;
      padding-right: 10px;
    }
  }
}
.fireScore {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 95%;
  > div {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.4);
    margin: 0.2rem;
    padding: 0.2rem;
    border-radius: 0.1rem;
    //box-shadow: 0 0 10px rgba(128, 128, 128, 0.39);
    > div:first-child {
      font-size: 15px;
      padding: 0.1rem 0;
    }
    > div:nth-child(2) {
      font-size: 25px;
    }
  }
}
.desc {
  padding: 0.3rem;
  font-size: 13px;
  color: rgba(93, 93, 96, 0.44);
}
.level {
  &A {
    background: linear-gradient(
      56deg,
      rgba(34, 152, 225, 0.61) 13%,
      rgb(34, 152, 225) 84%
    );
  }

  &B {
    background: linear-gradient(56deg, #0059f7 13%, rgb(34, 152, 225) 84%);
  }

  &C {
    background: linear-gradient(56deg, #ebc007 13%, rgb(34, 152, 225) 84%);
  }

  &D {
    background: linear-gradient(56deg, #b9333c 13%, rgb(34, 152, 225) 84%);
  }

  &E {
    background: linear-gradient(56deg, #000 13%, rgb(34, 152, 225) 84%);
  }
}
</style>
