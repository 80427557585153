var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card",class:{
      levelA: _vm.level === 'A',
      levelB: _vm.level === 'B',
      levelC: _vm.level === 'C',
      levelD: _vm.level === 'D',
      levelE: _vm.level === 'E',
    }},[_c('div',{staticClass:"level"},[_c('div',{staticClass:"level-number"},[_vm._v(_vm._s(_vm.level))]),_c('div',{staticClass:"level-title"},[_vm._v("安全等级")])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"content-name"},[_vm._v(_vm._s(_vm.cinemaName))]),_c('div',{staticClass:"content-line"}),_c('div',{staticClass:"content-from"},[_vm._v("自省消防支队")])])]),_c('div',{staticClass:"fireScore"},[_c('div',{staticClass:"city"},[_c('div',[_vm._v("城市评分")]),_c('div',[_vm._v(" "+_vm._s(_vm.cityScore)+" ")])]),_c('div',{staticClass:"cinema"},[_c('div',[_vm._v("影院消防信用评分")]),_c('div',[_vm._v(" "+_vm._s(_vm.DistrictScore)+" ")])])]),_c('div',{staticClass:"desc"},[_vm._v(" 说明：消防安全等级按照A、B、C、D、E五个等级来进行划分分别对应五种不同颜色，数据每7天更新一次，Ａ优秀Ｂ良好Ｃ一般Ｄ不好Ｅ严重。 ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }